<template>
  <div>
    <xmHeader :activeIndex="0"></xmHeader>
    <main>
      <div class="banner-index sm:!h-[600px] relative">
        <!-- <div class="banner-bg2"></div> -->
        <div class="banner-bg1"></div>
        <!-- <swiper
          class="swiper"
          ref="bannerSwiper"
          :options="bannerSwiperOptions"
        >
          <swiper-slide
            v-for="(item, index) in PCData.top"
            :key="index"
            class="swiperItem"
          >
            <div class="text-container" v-if="index === 0">
              <div class="text-container-content w-1440 px-88">
                <span class="sm:text-[20px] sm:leading-[40px]">我们的愿景</span>
                <span class="line sm:!mt-[18px] sm:!mb-[16px]"></span>
                <span class="text sm:!text-[32px] sm:!leading-[48px]"
                  >中国领先的<br />开发者孵化运营平台</span
                >
              </div>
            </div>

            <div
              class="banner-img"
              :style="`background-image: url(${item.img})`"
            ></div>
          </swiper-slide>
        </swiper>
        <div class="banner-swiper-scrollbar-content w-1440 px-88">
          <div class="banner-nav-list">
            <div class="banner-swiper-button-prev"></div>
            <div class="banner-swiper-button-next"></div>
          </div>
          <div class="banner-swiper-scrollbar sm:!hidden"></div>
        </div> -->
        <div class="text-container fade-in-down">
          <div class="text-container-content w-1440 px-88 sm:!top-[90px]">
            <span class="sm:text-[16px] sm:leading-[40px]">我们的愿景</span>
            <span class="text sm:!text-[32px] sm:!leading-[44px]"
              >中国领先的<br class="hidden sm:block" />开发者孵化运营平台</span
            >
          </div>
        </div>
        <div class="banner-img md:hidden">
          <img src="./assets/banner-img.png" alt="" />
        </div>
        <div class="sm-banner-img hidden sm:flex">
          <img src="./assets/banner-img-sm.png" alt="" />
        </div>
        <div class="md-banner-img hidden md:flex sm:!hidden">
          <img src="./assets/banner-img-md.png" alt="" />
        </div>
      </div>
      <Summary />
      <ArticleContent />
      <!-- 加入xm -->
      <section class="w-1440">
        <p class="section-title pl-88 pt-80 fade-in-up mb-[60px] sm:mb-[32px]">
          一起探索未来
        </p>
        <div class="join fade-in-up">
          <div
            class="item md:!w-full md:h-[220px] sm:!h-[154px]"
            v-for="item in PCData.futureList"
            :key="item.title"
          >
            <img class="object-cover w-full h-full" :src="item.img" alt="" />
            <p
              class="bg md:flex md:justify-center md:items-center sm:text-[20px]"
              :style="{
                background: !item.hover
                  ? item.background
                  : item.backgroundHover,
              }"
              @mouseover="item.hover = true"
              @mouseleave="item.hover = false"
            >
              {{ item.title }}
            </p>
          </div>
        </div>
        <div
          class="join-btn hover:bg-[#005EE7] sm:!w-[154px] sm:!h-[48px] sm:!text-[15px] sm:!leading-[48px] sm:!mb-[60px]"
          @click="joinUs"
        >
          加入我们
        </div>
      </section>
    </main>
    <xmFooter></xmFooter>
  </div>
</template>

<script>
import xmHeader from "./../../components/xmHeader/xmHeader";
import xmFooter from "./../../components/xmFooter/xmFooter";
import Summary from "./../../components/Summary/index";
import ArticleContent from "./../../components/ArticleContent/index";
import pageData from "data/indexData";
import utils from "utils/utils";
export default {
  name: "index",
  components: {
    xmHeader,
    xmFooter,
    Summary,
    ArticleContent,
  },
  created() {
    this.PCData = pageData.PCData;
  },
  data() {
    return {
      PCData: {},
      bannerSwiperOptions: {
        direction: "horizontal",
        effect: "none",
        loop: true,
        autoplay: {
          delay: 3000,
          disableOnInteraction: false,
        },
        pagination: {
          el: ".banner-swiper-scrollbar",
          clickable: true,
        },
        navigation: {
          nextEl: ".banner-swiper-button-next",
          prevEl: ".banner-swiper-button-prev",
        },
        on: {
          click: function() {
            if (this.realIndex === 1) {
              window.open(
                "https://app.mokahr.com/campus-recruitment/xmiles/37612"
              );
            }
          },
        },
      },
    };
  },
  mounted() {
    // banner 图少于2时候，停止播放
    // if (this.PCData.top && this.PCData.top.length < 2) {
    //   this.$refs.bannerSwiper.swiper.autoplay.stop();
    // }
    utils.animateOnScroll(".fade-in-up");
  },
  methods: {
    joinUs() {
      window.open("https://app.mokahr.com/social-recruitment/xmiles/39484");
    },
  },
};
</script>

<style lang="scss" scoped>
@import "./index.scss";
</style>
