import Vue from "vue";
import index from "./index";
import animated from "animate.css";
import VueAwesomeSwiper from "vue-awesome-swiper";
// import ieUtils from './../../utils/ieUtils';
import "./../../css/global.scss";
import "swiper/dist/css/swiper.css";

Vue.use(animated);
Vue.use(VueAwesomeSwiper);

new Vue({
  render: (h) => h(index),
}).$mount("#app");
